/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader />

        <Column className="--center pb--80 pt--80" name={"wstęp"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--62" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"kontakt"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--top" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Title className="title-box" content={"Pańska 18, Warszawa"}>
              </Title>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, zmienić wielkość liter, zmienić odstępy między wierszami, wyjustować tekst oraz ustawić maksymalną szerokość tekstu w pikselach."}>
              </Text>

              <Text className="text-box" content={"Poniedziałek - Piątek:"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={"6:00 - 18:00"}>
              </Subtitle>

              <Text className="text-box" content={"Weekend:"}>
              </Text>

              <Subtitle className="subtitle-box mt--0" content={"6:00 - 18:00"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <ContactForm className="--shape2 --style3" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Pełne imię","type":"text","required":true,"placeholder":"Wpisz pełne imię"},{"name":"Kontakt e-mail","type":"email","required":true,"placeholder":"Wpisz swój e-mail"},{"name":"Wiadomość","type":"textarea","required":true,"placeholder":"Wpisz tekst wiadomości"},{"name":"Wyślij","type":"submit","align":"right"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1unftk pb--60 pt--60" name={"kontakt-0"} layout={"l1"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pt--10" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62 lh--1" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\">+48 797 811 2X0</span>"}>
              </Title>

              <Title className="title-box fs--62 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\">info@twoje-stronyy.com</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Ślusarstwo Kraków</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Pańska 18</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">+48 797 811 2X0<br>info@twoje-stronyy.com</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">REGON: 12345678<br>Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}